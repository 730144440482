.form-container {
  position: relative;
  margin-left: 22%;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 450px;
  padding: 60px;
  border: 5px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 200px;
  margin-top: 100px;
}

.form-container h1 {
  text-align:left;
}

.form-container .form-part {
  font-weight:bold;
  font-size:20px;
  text-align:left;
  margin-top:20px;
  margin-bottom:30px;
} 


.form-container  .form-group {
  margin-bottom: 0px;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  margin-bottom: 10px;
  font-weight: bold;
}

.form-container .MuiInputBase-input,
.form-container .MuiInputLabel-root {
  color: white;
}

.form-container .input-container .MuiTextField-root {
  width: 100%;
  margin-bottom: 10px; /* Ajout du margin entre les inputs */
}

.form-container.input-container {
  margin-bottom: 20px;
}

.form-container .button-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .form-container {
    width: 90%; /* Réduire la largeur du conteneur de formulaire pour les petits écrans */
    min-width: 0; /* Supprimer la largeur minimale */
    margin-left: 5%; /* Réinitialiser la marge gauche */
    margin-right: 0; /* Réinitialiser la marge droite */
    padding: 30px; /* Réduire le padding pour économiser de l'espace */
  }
}

@media screen and (max-width: 576px) {
  .form-container {
    margin-left: 3%;
    width: 95%; /* Réduire encore plus la largeur pour les écrans plus petits */
  }
  .form-container h1 {
    font-size:15px;;
  }
}



.form-container .gold-button {
  width:150px;
  font-size:1.2rem;
  background-color: #ffd700 !important;
  color: black !important;
  box-shadow: none;
}

.form-container .gold-button:hover {
  background-color: goldenrod !important;
  color:white !important;
  box-shadow: none;
}