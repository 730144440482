

/* Header */



.nav-header-container {
  position:relative;
  margin-top:20px;
  z-index:500;
}

.nav-header-container img {
  position:absolute;
  top:0px;
  z-index:2;
  width:300px;
  height:120px;
}



/* .nav-header-container img:hover  {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
} */

.header {
  height:60px;
  background-color:rgba(0,0,0,0.6) !important;
}


.header .header-links {
  position: relative;
  font-size: 13.5px;
  text-decoration: none !important;
  color: white !important;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
}

.header .header-links::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.6s ease-in-out;
}

.header .header-links:hover::before {
  width: 100%;
}

.header .header-links:hover {
  /* text-decoration: underline !important;
  text-decoration-color: gold!important; */
  font-weight: 600;
}







/* Avoid Padding Problems */





/* Dropdown */





/* Navbar */




.navbar-elements {
  height:60px;
  background-color:rgba(0,0,0,0.6) !important;
}




.navbar-links a {
  position: relative;
  color: white !important;
  font-size: 17px;
  border-radius: 10px;
  font-weight: 400;
  background-color: rgba(255, 215, 0, 0);
}

.navbar-links a::before {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.6s ease-in-out;
}

.navbar-links a:hover::before {
  width: 100%;
}

.navbar-links a:hover {
  font-weight: bold;
  /* text-decoration: underline;
  text-decoration-color: gold; */
}





.left-link {
  margin-left:0rem;
}

.right-link {
  margin-left:2rem;
}






@media (max-width: 576px) {
  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links a {
    margin-left: 0;
    margin-top: 0.5rem;
  }
  
  
}





@media (max-width: 991px) {
  .left-link {
    margin-right:0rem;
  }

  .right-link {
    margin-left:0rem;
  }
  .navbar-brand {
    display: none;
  }

}


