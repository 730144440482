.properties-map-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.properties-map-container .property {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 450px;
  height: 450px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  margin-right: 15px;
}

.properties-map-container .image-container img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.image-container {
  position: relative;
}

.favorite-button {
  position: absolute;
  top: 10px;
  left: 90%;
  border: none;
  background-color:transparent;
  color: #ff0000;
  cursor: pointer;

}



.properties-map-container .info {
  height: 200px;
  display: flex;
  padding:30px;
  flex-direction: column;
}

.properties-map-container .property-type {
  font-size: 22px;
  font-weight: bold;
  align-self: flex-start; /* Aligner à gauche */
}

.first-info {
  display: flex;
  justify-content: space-between; /* Espacement entre le type de bien et le prix */
}

.properties-map-container .price {
  font-size: 22px;
  font-weight: bold;
  align-self: flex-end; /* Aligner à droite */
}

.properties-map-container .location {
  margin-top:5px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
}

.properties-map-container .features {
  margin-top:25px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row; /* Mettre les informations en ligne */
}

.properties-map-container .feature {
  font-size: 14px;
  background-color: #f2f2f2; /* Ajouter un fond stylé */
  padding: 5px 10px; /* Ajouter un espacement autour des informations */
  border-radius: 10px; /* Ajouter un rayon stylé */
}

.properties-map-container .feature .feature-label {
  font-weight: bold;
  margin-right: 5px;
}

.properties-map-container .carousel-control-prev,
.properties-map-container .carousel-control-next {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.properties-map-container .carousel-control-prev {
  left: 10px;
}

.properties-map-container .carousel-control-next {
  right: 10px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.pagination-container .pagination .page-link {
  color: #333;
  font-weight: bold;
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s;
}

.pagination-container .pagination .page-link:hover {
  background-color: #ddd;
  cursor: pointer;
}

.pagination-container .pagination .page-link.active {
  background-color: #333;
  color: #fff;
}


@media (max-width: 575px) {



  .properties-map-container .property {
    text-align:center;
    width: 100%;
    height: 600px;

  }
  
  .properties-map-container .image-container img {
    width: 100%;
    height: 350px;
  }
  
  .properties-map-container .info {
    height: 300px;
  }

  .properties-map-container .info {
    display: flex;
    padding:30px;
    flex-direction: column;
  }
  
  .properties-map-container .property-type {
    align-self:center; 
  }
  
  .first-info {
    flex-direction: column;
    justify-content: center; /* Espacement entre le type de bien et le prix */
  }
  
  .properties-map-container .price {
    align-self:center; 
  }
  
  .properties-map-container .features {
    margin-top: 25px;
    display: flex;
    justify-content: none;
    flex-direction: row; /* Mettre les informations en ligne */
    gap: 10px; /* Ajouter un espace de 10 pixels entre les enfants */
  }


}