.appBar {
    display:flex;
    flex-wrap: wrap;
    background-color: white !important;
    color: black !important;
    position: relative !important;
    left:0 !important;
    top:0 !important;
  }

  .desktopNav {
    margin-left:10px;
  }
  
  .navButton {
    color: black !important;
    font-size: 1.6vh !important;
  }
  
  .navButton:hover {
    /* background-color: gold !important; */
     background-color: transparent !important;
    color:black !important;
  }

  .headButton:hover {
    /* text-decoration: underline !important;
    text-decoration-color: gold !important; */
    color:black !important;
    font-weight: bold !important;
  }

  