




html .login-container  {
    background-color: rgba(0, 0, 0, 0.8);
}




.login-container {
    position: relative;
    width: 100%;
    height: auto;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    border: rgba(0, 0, 0, 0) 10px solid;
}

.login-form-container {
    position: relative;
    margin-left: 22%;
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 450px;
    padding: 60px;
    border: 5px solid #dddddd;
    border-radius: 5px;
    margin-bottom: 100px;
    margin-top: 100px;
}









.login-form-container label {
    margin-bottom: 10px;
    font-weight: bold;
}

.login-form-container .MuiInputBase-input,
.login-form-container .MuiInputLabel-root {
    color: white;
}






@media screen and (max-width: 768px) {
    .login-form-container {
        width: 90%;
        min-width: 0;
        margin-left: 5%;
        margin-right: 0;
        padding: 30px;
    }
}

@media screen and (max-width: 576px) {
    .login-form-container {
        margin-left: 0%;
        width: 95%;
    }
    .login-form-container h1 {
        font-size: 15px;
    }

    .login-form-container .gold-button {
        font-size: 1.7vh !important;
    }
}


.login-form-container .gold-button {
    margin-top:20px;
    width:40%;
    background-color: #ffd700 !important;
    box-shadow: none;
    color: black;
    font-size: 16px;
    cursor: pointer;
}
  
.login-form-container .gold-button:hover {
    background-color: goldenrod !important;
    color:white !important;
    box-shadow: none;
}


  


