
.latest-properties-container {
    background-color: #fdfaf4;
    border:1px solid #fdfaf4;
}

.latest-properties-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.latest-properties-container .title {
    text-align:center;
    margin-top:200px;
    margin-bottom:50px;
}

.latest-properties-container .gold-button {
    width:15rem;
    font-size:16px;
    background-color: #ffd700 !important;
    color: black !important;
    box-shadow: none;
    cursor: pointer;
    margin-top:-50px;
    margin-bottom:200px;
}
  
.latest-properties-container .gold-button:hover {
    background-color: goldenrod !important;
    color:white !important;
    box-shadow: none;
  }


  
  

  