.about1 .about1 {
  background-color: #fdfaf4;
}

/* Button */


.about1 .gold-button {
  width:250px;
  height:40px;
  background-color: #ffd700 !important;
  color: black !important;
  box-shadow: none;
  cursor: pointer;
  margin-bottom:10px;
}
.about1 .gold-button:hover {
  background-color: goldenrod !important;
  color:white !important;
  box-shadow: none;
}




/* Structure box */

.about1 .row {
  display: flex;
  justify-content: center;
}

.about1 .box-infos .description {
  width: 100%;
}

.about1 .box-intro {
  margin-top: 80px;
}

.about1 .box-infos {
  margin-top: 250px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about1 .box-infos .button-group {
  margin-top: 40px;
}

.about1 .box-infos .more-infos {
  margin-top: 40px;
}

.about1 .box-intro,
.about1 .box-team,
.about1 .box-comment {
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
}

.about1 .box-team {
  margin-bottom: 130px;
  margin-top: 0px;
}

.about1 .button-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Img */

.about1 .intro-img {
  width: 80%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.about1 .agency-img {
  width: 100%;
  height: 350px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.about1 .team-img {
  width: 300px;
  height:50%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1624px) {
  .about1 .box-infos .description {
    width: 80%;
  }
}

@media screen and (max-width: 1224px) {
  .about1 .box-infos {
      margin-top: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .about1 .box-intro {
    margin-top: 100px;
  }
}
