.news-container {
padding: 20px;
background-color: #fdfaf4;
/* border:black solid 5px; */
}

.news-container .even-row {
    margin-top:100px;
    margin-bottom:100px;
}

.news-container .odd-row {
    margin-top:100px;
    margin-bottom:100px;
}


.news-container .image {
    margin-top:40px;
    margin-bottom:40px;
}

.news-container .part-image {
width: 100%;
height: 400px;
object-fit: cover;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-container .part-subtitle {
margin-top: 15px;
font-size: 25px;
font-weight: bold;
text-align:center;
}

.news-container .part-description {
font-size: 16px;
margin-top: 40px;
color: #666666;
text-align:center;
}

