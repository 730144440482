

.footer  {
  background-color: #f9f9f9;
  padding: 2rem 0;
  box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.1); /* Ajoute une ombre sur le dessus et le dessous du footer */
}


.footer h5 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-line {
  border: 1px solid #ccc;
}

.button-list {
  list-style-type: none;
  padding: 0;
}




.footer-el {
  display: flex;
  justify-content: space-between;
}


.compagny-right {
  margin-left:10%;
  font-weight: bold;
}






.footer .custom-link {
  margin-top:20px;
  text-decoration: none;
  color: black;
  position: relative;
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: none;
}

.footer .custom-link::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 0.6s ease-in-out;
}

.footer .custom-link:hover::before {
  width: 100%;
}

.footer .custom-link:hover {
  font-weight: 600;
}