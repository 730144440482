.renovation-container {
  background-color: #fdfaf4;
}

.renovation-container h2 {
  margin-left: 10%;
  padding-bottom: 60px;
  padding-top: 60px;
}

.renovation-container .central-block {
  display:flex;
  flex-direction: row;
  position:relative;
  width: 80%;
  margin: 0 auto;
  background-color: #f9f9f9;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  height:1000px;
}



.renovation-container .box1 {
  width: 35%;
  margin-top:70px;
  margin-left:8%;
}

.renovation-container .box2 {
  width:100px;
  margin-top:70px;
  margin-left:10%;
  margin-right:10%;
}

.renovation-container .box3 {
  width: 35%;
  margin-top:300px;
  margin-right:5%;
}

.renovation-container .section1,
.renovation-container .section2,
.renovation-container .section3,
.renovation-container .section4 {
  width: 100%;
  height: 450px;
}


.renovation-container .box1 {
  order: 1;
}

.renovation-container .box2 {
  position:relative;
  order: 2;
}

.renovation-container .box3 {
  order: 3;
}


.renovation-container .vertical-bar {
  position:absolute;
  left:51.3%;
  width:5px;
  height:800px;
  background-color:black; 
}

.renovation-container .circle1,
.renovation-container .circle2,
.renovation-container .circle3,
.renovation-container .circle4 {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color:rgba(0, 0, 0, 1); 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px; /* Espacement vertical entre les cercles */
}




.renovation-container .box2-responsive {
  display:none;
}



@media screen and (max-width: 576px) {
  .renovation-container .central-block {
    height:1400px;
  }



  .renovation-container .box2 {
    visibility:hidden;
  }

  .renovation-container .box1 {
    position: relative;
    left:0%;
  }


  .renovation-container .box3 {
    position: relative;
    left:0%;
    top:80px;
  }

  .renovation-container .section1,
  .renovation-container .section2,
  .renovation-container .section3,
  .renovation-container .section4 {
    width: 100%;
    margin-bottom:80px;
    height: 250px !important;
    /* border:5px solid black; */
  }


  .renovation-container .box2-responsive {
    display:block;
    position:absolute;
    margin-left:3%;
  }
  
  .renovation-container .vertical-bar-responsive {
    position:absolute;
    margin-left:45px;
    top:0;
    width:5px;
    height:1140px;
    background-color:black;
    /* border:1px solid black; */
  }

  .renovation-container .circle-responsive {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: black;
    margin-top: 150px; /* Espacement vertical entre les cercles */
    margin-bottom: 260px; /* Espacement vertical entre les cercles */
  }

  .renovation-container .vertical-bar {
    display:none;
  }




}


@media screen and (max-width: 767px) {
  .renovation-container .central-block {
    font-size:13px;
  }

}




@media (max-width: 1199px) {

  .renovation-container .box2 {
    order: 1;
  }
  
  
  .renovation-container .box1 {
    order: 2;
  }
  

  .renovation-container .box3 {
    order: 3;
  }

  
  .renovation-container .central-block {
    flex-wrap: wrap;
    width: 95%;
  }
  
  
  
  .renovation-container .box1 {
    width: 60%;
    margin-top:70px;
    margin-left:8%;
  }
  
  .renovation-container .box2 {
    width: 8%;
    margin-top:70px;
    margin-left:5%;
    margin-right:10%;
  }
  
  .renovation-container .box3 {
    width: 60%;
    margin-top:-460px;
    margin-right:0%;
    margin-left:31%;

  }
  
  .renovation-container .section1,
  .renovation-container .section2,
  .renovation-container .section3,
  .renovation-container .section4 {
    height: 235px;
  } 

  .renovation-container .vertical-bar {
    position:absolute;
    left:5.5em;
    width:5px;
    height:800px;
    background-color:black;
  }

  .renovation-container .circle1,
  .renovation-container .circle2,
  .renovation-container .circle3,
  .renovation-container .circle4 {
    margin-bottom: 150px; /* Espacement vertical entre les cercles */
  }
}





/* Second part */

.renovation-container .form-block {
  position: relative;
  width: 100%;
  height: auto;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border: rgba(0, 0, 0, 0) 10px solid;
}

.renovation-container .form-block .form-title {
  font-size:35px;
  margin-left: 10%;
  margin-bottom: 60px;
  margin-top: 60px;
}

.renovation-container .form-block .form-sub-title {
  font-size:25px;
  text-align:center;
}


@media screen and (max-width: 576px) {
  .form-block .form-title {
    font-size:25px;
  }
}



