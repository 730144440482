.website-start {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fdfaf4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo-container {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.website-start .logo {
  width: 300px; /* Ajustez la taille du logo selon vos besoins */
  height: auto;
}
