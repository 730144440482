
.home-container {
  width:100%;
  /* border:solid black 5px; */
}



/* first block */


/* Styles pour la première section */

.redirection-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.redirection-container .box1 {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}


.redirection-container .box1 .description {
  width: 80%;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(253, 250, 244, 0.8);
  padding: 10px;
  border-radius: 3px;
  color: rgb(50, 50, 50);
  margin-top: 30px;
  margin-bottom: 30px;
}

.redirection-container .box1 .title {
  font-size: 2.5rem;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
  background-color: rgba(253, 250, 244, 0.8);
  padding: 10px;
  border-radius: 3px;
}

.redirection-container .box1 .sub-title {
  background-color: rgba(253, 250, 244, 0.8);
  padding: 10px;
  border-radius: 3px;
  font-size: 2rem;
  margin-top: 30px;
  margin-bottom: 20px;
}

.redirection-container .button-group {
  display: flex;
  justify-content: center;
  flex-direction:row;
}

.redirection-container .button-box {
  position: relative;
  background-color: #fdfaf4;;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 1.5rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
  margin-left:10px;
  margin-right:10px;
}

.redirection-container .box1 a {
  font-size: 30px;
  transition: none;
}

.redirection-container .box1 a:hover {
  font-size: 30px;
  color: black;
}

/* Styles pour la deuxième section */

.redirection-container .box2 {
  background-color: #fdfaf4;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.redirection-container .box2 .description {
  width: 60%;
  font-size: 1.2rem;
  text-align: center;
  color: rgb(50, 50, 50);
  margin-top: 30px;
  margin-bottom: 30px;
}

.redirection-container .box2 .description2 {
  width: 80%;
  font-size: 1.2rem;
  text-align: center;
  color: rgb(50, 50, 50);
  margin-top: 30px;
  margin-bottom: 30px;
}

.redirection-container .box2 .title {
  font-size: 2.5rem;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.redirection-container .box {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
}

.redirection-container .box2 .vertical-bar {
  position: relative;
  width: 3.5px;
  height: 85%;
  background-color: black;
  margin: 0 20px;
}

.redirection-container .sub-box {
  width: 49%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
}

.redirection-container .button-box2 {
  position: relative;
  background-color: #fdfaf4;
  margin-bottom: 50px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 50px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 1.5rem !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.redirection-container .box2 a {
  font-size: 30px;
  transition: none;
}

.redirection-container .box2 a:hover {
  font-size: 30px;
  color: black;
}

/* Styles pour la mise en page responsive */

@media screen and (max-width: 1450px) {


  .redirection-container .box1,
  .redirection-container .box2 {
    width: 100%;
  }




  .redirection-container .button-group {
    display: flex;
    flex-direction: row;
  }

  .redirection-container .box1,
  .redirection-container .box2 {
    width: 100%;
  }

  .redirection-container .box2 .vertical-bar {
    display:none;

  }

  .redirection-container .box {
    display: flex;
    flex-direction: column;
  }

  .redirection-container .sub-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height:auto;
  }

  .redirection-container .box2 .title,
  .redirection-container .box1 .title{
    margin-top:50px;
    font-size: 1.8rem;
  }
  .redirection-container .box1 .sub-title{
    margin-top:50px;
    font-size: 1.6rem;
  }
  .redirection-container .box2 .sub-title{
    margin-top:30px;
    font-size: 1.6rem;
  }

  .redirection-container .box2 .description,
  .redirection-container .box2 .description2 {
    margin-top:50px;
    font-size: 1.3rem;
  }

  .redirection-container .button-box, 
  .redirection-container .button-box2 {
    margin-top:50px;
    margin-left:10px;
    margin-right:10px;
  }










  .redirection-container .box2 .vertical-bar {
    display: none;
  }

  .redirection-container .sub-box {
    width: 100%;
    height: auto;
  }

  
}

@media screen and (max-width: 767px) {

  
  .redirection-container .box1 .title{
    margin-top:50px;
    font-size: 1.8rem;
  }

  .redirection-container .box2 .title {
    margin-top:100px;
    font-size: 1.8rem;
  }

  .redirection-container .box1 .sub-title{
    margin-top:20px;
    font-size: 1.6rem;
  }

  .redirection-container .box2 .sub-title{
    margin-top:0px;
    font-size: 1.6rem;
  }

  .redirection-container .box2 .description,
  .redirection-container .box2 .description2 {
    margin-top:50px;
    font-size: 1.3rem;
  }

  .redirection-container .button-group {
    display: flex;
    justify-content: center;
    flex-direction:column;
  }



  .redirection-container .button-box2 {
    margin-top:50px;
    margin-bottom:100px;
  }

  .redirection-container .box1 .description {
    width: 100%;
  }
}






/* INFO BLOCK  */

::selection {
  background-color: rgba(0, 0, 0, 0.2); /* Remplacez "red" par la couleur de votre choix */
}


.infos-container {
  position:relative;
  width: 100%;
  background-color:#fdfaf4;
  /* border :black 1px solid; */
}


.infos-container h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  text-align:center;

}

.infos-container p {
  margin: 10px 0;
}

.infos-container .box h2 {
  font-size: 1.5rem;
  margin-top:40px;
  margin-bottom:60px;
}

.infos-container .box{
  margin-top: 50px;
  margin-bottom: 50px;
}



.infos-container .box4 {
  position:relative;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  padding: 20px;
  text-align: center;
  position: relative;
  /* border:black solid 5px; */
}

.infos-container .box4 .image1 {
  /* Les styles de base */
  z-index: 2;
  object-fit:cover;
  position: relative;
  top: -0px;
  left: -0%;
  width: 100%;
  height: 100%;
}






.infos-container .box2 .image3 {
  object-fit: cover;
  height: auto;
  width: 100%; /* Ajuste la taille en fonction de tes besoins */
  height: 100%; /* Ajuste la taille en fonction de tes besoins */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.infos-container .box3 {
  z-index:1;
  height: 800px;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  padding: 20px;
  text-align: center;
  /* border:black solid 5px; */
  
}

.infos-container .box2 {
  height: 500px;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  padding: 20px;
  text-align: center;
  /* border:black solid 5px; */
}



.infos-container .box1 {
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  /* border:black solid 5px; */
}

/* Margin */



@media screen and (max-width: 767px) {

  .infos-container .box4 {
    display:none;
  }

  .infos-container .box1 {
    margin-top:0px !important;
  }
  

  
  

}





.comments-part {
  overflow-y: auto;
  height: 600px;
  margin-top: 30px;
}

.comment-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}








.comment-img {
  position:relative;
  max-width: 100%;
  height: auto;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}








.comment-box h3 {
  color: white;
  font-size: 1.4rem;
  border-radius: 10px 10px 0 0;
  padding:10px;
  background-color: rgba(0, 0, 0, 0.5);
}





.comments-part::-webkit-scrollbar {
  width: 8px; /* Largeur de la barre de défilement */
}

.comments-part::-webkit-scrollbar-track {
  background-color: white; /* Couleur de l'arrière-plan de la barre de défilement */
}

.comments-part::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur du curseur de la barre de défilement */
  border-radius: 4px; /* Arrondi du curseur de la barre de défilement */
}































