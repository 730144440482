.sorting-container {
  width: 70%;
  height:auto;
  min-width:350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items:center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px BLACK; 
}


.custom-select {
  width:130px;
}


.sorting-container .gold-button {
  width:150px;
  background-color: #ffd700 !important;
  color: black !important;
  box-shadow: none;
}


.sorting-container .gold-button:hover {
  background-color: goldenrod !important;
  color:white !important;
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .sorting-container {
    width: 100%;
  }
  .custom-select {
    width:130px;
  }
  
}