.property-page {
  width: 100%;
  background-color: #fdfaf4 !important;
}

.property-page .box1 {
  margin-top: 100px;
  height: 500px;
}

.property-page .box2 {
  display: flex;
  flex-direction: column;
  height: 500px;
  margin-top: 100px;
}

.first-row {
  margin-bottom:100px;
}

.property-page .image-container {
  display: flex;
  width: 100%;
  height: 500px;
}

.property-page .box2 .image1,
.property-page .box2 .image2 {
  width: 100%;
  height: 48%;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 20px;
}

.property-page .box1 .image1 {
  border-radius: 5px 0 0 5px;
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.custom-carousel {
  height: 500px;
}

.property-page .custom-carousel .carousel-inner {
  height: 500px;
}

.property-page .box3 {
  margin-top: 150px;
}

@media (max-width: 1024px) {
  .property-page .box3 {
    margin-top: 50px;
  }
}

.property-page .box4 {
  margin-top: 130px;
}

.property-page .box3 {
  margin-left: 0%;
}

.property-page .box4 .form-container .MuiInputBase-input,
.property-page .box4 .form-container .MuiInputLabel-root {
  color: black !important;
}

.property-page .box4 .form-container {
  margin-left: 0% !important;
  width: 100% !important;
  min-width: none !important;
  padding: 60px;
  border-radius: 5px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  background-color: white !important;
}


.property-title {
  margin-top:100px;
  position: relative;
  text-align:center;
  transition: transform 0.3s ease;
  
}

.property-description {
  width:100%;
  margin-top: 80px;
  transition: transform 0.3s ease;
}

.property-page .vertical-bar {
  margin-top:300px !important;
  background-color:black;
  width:500px !important;
  margin-left:-200px;
  margin-right:-200px;
  height:1px !important;
  rotate: 90deg;
}

.technical-box {
  width:100%;
  margin-top: 80px;
  transition: transform 0.3s ease;
}








.property-form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.banner {
  /* background-color: #ffffff; */
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* border: 2px solid rgba(0, 0, 0, 0.3); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}


@media (max-width: 1424px) {
  .banner {
    width: 50%;
  }
}


@media (max-width: 1024px) {
  .banner {
    width: 70%;
  }



  
  
  
}

@media (max-width: 1000px) {
  .vertical-bar {
    display:none;
  }


}

@media (max-width: 768px) {
  .property-page .box1,
  .property-page .box2 {
    height: auto;
  }




  .property-page .box2 .image1,
  .property-page .box2 .image2 {
    height: 200px;
  }

  .property-page .box3 {
    margin-top: 50px;
  }

  .banner {
    margin-top: 50px;
    width: 90%;
  }



  .property-title {
    border-radius: 10px;
    padding: 20px;
    /* text-decoration: underline;
    text-decoration-color: black; */
  }

  .property-description {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .technical-box {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }



  
}

@media (max-width: 576px) {
  .property-page .box2 .image1,
  .property-page .box2 .image2 {
    height: 150px;
  }

  .property-page .box3 {
    margin-top: 30px;
  }

  .property-page .banner {
    margin-top: 30px;
    padding: 10px;
  }

  .property-page .toggle-button {
    padding: 8px 16px;
  }
}


/* BUTTON */


.property-page .gold-button {
  width:50%;
  background-color: #ffd700 !important;
  box-shadow: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
  margin-left:5px;
  margin-right:5px;
}

.property-page .gold-button:hover {
  background-color: goldenrod !important;
  color:white !important;
  box-shadow: none;
}










/* fullscreen  */

.modal-content {
  background-color:RED;
  width: 0vw;
  height: 0vh;
}

.modal-body {
  background-color:transparent;
  padding: 0;
  display: flex;
  justify-content:center;
  margin-top:-350px;
}

.fullscreen-image {
  display: block;
  width: 70vw;
  height: 70vh;
  object-fit: contain;
}




































