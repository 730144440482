.inline-sorting-container {
  margin-top:60px;
  width: 95%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center; /* Ajout de cette ligne */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px black;
  padding: 10px;
}

.top-offset {
  margin-top: 10px;
  margin-bottom:10px ;
}

.inline-sorting-container .checkbox-group {
  display: flex;
  flex-direction: row;
}

.inline-sorting-container .custom-select.MuiInputBase-root,
.inline-sorting-container .custom-textfield .MuiInputBase-root {
  display: flex;
  height: 40px;
  width: 100%;
}

.inline-sorting-container .custom-select.MuiInputBase-root .MuiInputLabel-root,
.inline-sorting-container .custom-textfield .MuiInputLabel-root,
.inline-sorting-container .custom-select.MuiInputBase-root .MuiInputBase-input,
.inline-sorting-container .custom-textfield .MuiInputBase-input {
  color: black;
  text-align: center;
}

.inline-sorting-container .search-button {
margin-left: 10px;
}

.inline-sorting-container .gold-button {
  width:150px;
  background-color: #ffd700 !important;
  color: black !important;
  box-shadow: none;
}


.inline-sorting-container .gold-button:hover {
  background-color: goldenrod !important;
  color:white !important;
  box-shadow: none;
}

.secteur {
  margin-top:15px;
}


@media (max-width: 567px) {
  .top-offset {
    margin-top: 20px;
    margin-bottom:20px ;
  }

  .secteur {
    margin-top:25px;
  }
  
}
