.properties-page-container .title {
    margin-top:50px;
    margin-bottom:50px;
    text-align:CENTER;
    
}

.properties-page-container {
    background-color:#fdfaf4;
    border:#fdfaf4 solid 1px;
}

