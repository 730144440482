body {
  background-color: #fdfaf4 !important;
}

.profile-page .second-part {
    margin-top:100px;
    margin-bottom:100px;
}

.profile-page .h3 {
    text-align:center;
}

.profile-page .user-infos { 
  margin-top:100px;
  margin-bottom:100px 
}




.profile-page .property {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 450px;
  height: 450px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  margin-right: 15px;
}


.profile-page .favorites-part {
  margin-top:30px;
  height: 400px;
  overflow-y: auto;
  width:450px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.favorite-button {
  position: absolute;
  top: 10px;
  left: 85%;
  border: none;
  background-color:transparent;
  color: #ff0000;
  cursor: pointer;

}



.profile-page .image-container img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.profile-page .info {
  height: 200px;
  display: flex;
  padding: 30px;
  flex-direction: column;
}

.profile-page .property-type {
  font-size: 22px;
  font-weight: bold;
  align-self: flex-start;
}

.profile-page .price {
  font-size: 22px;
  font-weight: bold;
  align-self: flex-end;
}

.profile-page .location {
  margin-top: 5px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
}

.profile-page .features {
  margin-top: 25px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.profile-page .feature {
  font-size: 14px;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 10px;
}

.profile-page .feature .feature-label {
  font-weight: bold;
  margin-right: 5px;
}

.profile-page .carousel-control-prev,
.profile-page .carousel-control-next {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-page .carousel-control-prev {
  left: 10px;
}

.profile-page .carousel-control-next {
  right: 10px;
}




@media (max-width: 575px) {
  .profile-page .property {
    text-align: center;
    width: 100%;
    height: 600px;
  }

  .profile-page .image-container img {
    width: 100%;
    height: 350px;
  }

  .profile-page .info {
    height: 300px;
  }

  .profile-page .info {
    display: flex;
    padding: 30px;
    flex-direction: column;
  }

  .profile-page .property-type {
    align-self: center;
  }

  .profile-page .first-info {
    flex-direction: column;
    justify-content: center;
  }

  .profile-page .price {
    align-self: center;
  }

  .profile-page .features {
    margin-top: 25px;
    display: flex;
    justify-content: none;
    flex-direction: row;
    gap: 10px;
  }
}


.profile-page .favorites-part::-webkit-scrollbar {
  width: 8px; /* Largeur de la barre de défilement */
}

.profile-page .favorites-part::-webkit-scrollbar-track {
  background-color: white; /* Couleur de l'arrière-plan de la barre de défilement */
}

.profile-page .favorites-part::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur du curseur de la barre de défilement */
  border-radius: 4px; /* Arrondi du curseur de la barre de défilement */
}
